@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;800&display=swap');

body {
  font-family: 'JetBrains Mono', monospace;
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #736357;
}

input, button {
  font-family: 'JetBrains Mono', monospace;
}

#root {
  width: 100%;
  height: 100%;
}

input {
  outline: 0;
}

button {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
